@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-100;

    margin: 0;
    font-family: 'Lexend', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Lexend', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}